<template>
  <ModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <form ref="modal" class="common-modal">
      <div class="modal-header" :class="$style.header">
        <h1 class="h4" :class="$style.title">{{ data.title }}</h1>
        <SvgoIconClose class="icon-close" @click="$emit('close')" />
      </div>
      <div :class="$style.column">
        <div :class="$style.text" class="common-text">
          {{ data.message }}
        </div>
        <VButton
          v-if="data.buttonText"
          block
          :label="data.buttonText"
          @click="$emit('close')"
        />
      </div>
    </form>
  </ModalWrapper>
</template>

<script setup lang="ts">
type Props = {
  visible: boolean;
  data: {
    title?: string;
    message?: string;
    buttonText?: string;
  };
};

defineProps<Props>();

const emit = defineEmits(["after-leave", "after-enter", "close"]);

const modal = ref(null);

onClickOutside(modal, () => {
  emit("close");
});
</script>

<style lang="scss" module>
.title {
  text-align: center;
  width: 100%;
}

.header {
  margin-bottom: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.text {
  text-align: center;
  line-height: 140%;
  color: var(--text-color-6);
  white-space: break-spaces;
}
</style>
