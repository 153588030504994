<template>
  <transition
    name="form-fade"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div v-if="visible" :class="$style.ModalWrapper">
      <slot />
    </div>
  </transition>
</template>

<script setup lang="ts">
defineProps({
  visible: Boolean,
});

const emit = defineEmits(["close", "after-leave", "after-enter"]);

onMounted(() => {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      emit("close");
    }
  });
});
</script>

<style lang="scss" module>
.ModalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 22;
  display: flex;
  justify-content: center;
  width: 100%;

  @include respond-to(xs) {
    width: 90%;
  }
}
</style>
